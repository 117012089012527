<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="1550px"
    persistent
    scrollable
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].name"
                :rules="nameRules"
                class="mt-4"
                :label="$t('FORM_INPUT_NAMES.title')"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.name']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.name'] = ''
                "
                @change="handleChangeName"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-if="
                  formModel.id && formModel.translations[selectedLocale.lang]
                "
                class="mt-4"
                v-model="formModel.translations[selectedLocale.lang].slug"
                :label="$t('FORMS.slug')"
                :rules="nameRules"
                :id="dynamicID"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.slug']
                "
                @keyup="
                  messages['translations.' + selectedLocale.lang + '.slug'] = ''
                "
              ></v-text-field>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
            <v-col cols="12" sm="2" md="2" class="mt-3">
              <v-menu
                ref="date_picker_published_at"
                v-model="date_picker_published_at"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="published_at_date"
                    :label="$t('FORMS.date_of_published_at')"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="published_at_date"
                  no-title
                  scrollable
                  locale="hu-HU"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="date_picker_published_at = false"
                  >
                    {{ $t("FORMS.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.date_picker_published_at.save(published_at_date)
                    "
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="d-flex flex-row mt-3">
              <v-menu
                ref="time_picker_published_at"
                v-model="time_picker_published_at"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="published_at_time"
                    :label="$t('FORMS.time_of_published_at')"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  :disabled="!published_at_date"
                  v-if="time_picker_published_at"
                  v-model="published_at_time"
                  format="24hr"
                  full-width
                  @click:minute="
                    $refs.time_picker_published_at.save(published_at_time)
                  "
                ></v-time-picker>
              </v-menu>
              <v-icon
                color="primary"
                class="align-self-center"
                small
                @click="
                  published_at_time = null;
                  published_at_date = null;
                "
              >
                flaticon2-delete
              </v-icon>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4" md="4" class="pt-4">
              <v-autocomplete
                v-model="formModel.categories"
                :items="newsCategoryCollection"
                attach
                chips
                deletable-chips="true"
                item-text="name"
                item-value="id"
                :label="$t('MENU.NEWS_CATEGORIES')"
                multiple
                @change="handleChangeCategory"
                dense
              >
                <template #selection="{ item }">
                  <v-chip
                    color="bg-gray-100"
                    close
                    @click:close="deleteChip(item, formModel.categories)"
                    >{{ item.name }}</v-chip
                  >
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-select
                v-if="statuses.news"
                v-model="formModel.status"
                :items="statuses.news.news"
                :label="$t('FORMS.status')"
                item-value="key"
              >
                <template v-slot:selection="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t("STATUSES." + data.item.value) }}
                </template>
              </v-select>
            </v-col>

            <v-col cols="12" sm="1" md="1">
              <v-checkbox
                class="mt-4"
                v-model="formModel.on_the_main_page"
                :label="$t('FORM_INPUT_NAMES.on_the_main_page')"
              ></v-checkbox>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                class="mt-4"
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="
                  formModel.translations[selectedLocale.lang].hovered_title
                "
                :label="$t('FORM_INPUT_NAMES.hovered_title')"
              ></v-checkbox>
            </v-col>
            <v-col cols="11" sm="2" md="2" class="d-flex flex-row"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5" class="py-0 my-0">
              <v-text-field
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.on_the_front_page
                "
                v-model="
                  formModel.translations[selectedLocale.lang].alternative_title
                "
                class="mt-4"
                :rules="max_50"
                :label="$t('FORM_INPUT_NAMES.alternative_title')"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="2" md="2">
              <v-checkbox
                class="mt-4"
                v-model="formModel.on_the_front_page"
                :label="$t('FORM_INPUT_NAMES.on_the_front_page')"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" sm="2" md="2" class="mb-3">
              <v-checkbox
                class="mt-4"
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="
                  formModel.translations[selectedLocale.lang].video_content
                "
                :label="$t('FORM_INPUT_NAMES.video_content')"
              ></v-checkbox>
            </v-col>
            <v-col cols="11" sm="2" md="2" class="d-flex flex-row mb-3">
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="5" md="5" class="mt-3">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'text',
                  label: 'FORM_INPUT_NAMES.og_title',
                }"
                customFieldName="og_title"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_title || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-autocomplete
                v-model="formModel.news_author_id"
                :label="$t('FORM_INPUT_NAMES.author_name')"
                :items="newsAuthorCollection"
                item-text="name"
                item-value="id"
                clearable="true"
                class="mt-4"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="mt-4">
              <v-combobox
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].tags"
                :items="computedTagCollection"
                :label="$t('FORM_INPUT_NAMES.tags')"
                multiple
                attach
                chips
                deletable-chips="true"
                dense
              >
                <template #selection="{ item }">
                  <v-chip
                    close
                    color="bg-gray-100"
                    @click:close="
                      deleteChipComboBox(
                        item,
                        formModel.translations[selectedLocale.lang].tags
                      )
                    "
                    >{{ item }}</v-chip
                  >
                </template>
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="3" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="featured_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .featured_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.featured_image',
                  resolution: '933x504',
                  selectButtonTextNotTransalated: '',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="3" sm="3" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="card_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .card_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.card_image',
                  resolution: '297x160',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" sm="3" md="3" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="og_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  selectButtonText: 'FORM_INPUT_NAMES.og_image',
                  resolution: '1200x630',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12" md="3" sm="3" xs="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="leading_article_image"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .leading_article_image || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: 1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                  ],
                  selectButtonText: 'FORM_INPUT_NAMES.leading_article_image',
                  resolution: '618x464',
                  selectButtonTextNotTransalated: '',
                  initialColumnClass:
                    'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].felcim"
                class="mt-4"
                :label="$t('FORM_INPUT_NAMES.felcim')"
                :id="dynamicID"
              ></v-text-field>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" sm="3" md="3">
              <v-checkbox
                class="mt-7"
                v-model="formModel.rss_nso"
                :label="$t('FORM_INPUT_NAMES.rss_nso')"
              ></v-checkbox>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.rss_nso
                "
                v-model="formModel.translations[selectedLocale.lang].rss_title"
                :rules="max_70"
                class="mt-4"
                :label="$t('FORM_INPUT_NAMES.rss_title') + ' ' + RSSLength"
                :id="dynamicID"
                @input="calcRSSLength"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div style="max-width: 785px">
                <v-textarea
                  v-if="formModel.translations[selectedLocale.lang]"
                  outlined="true"
                  :label="$t('FORMS.lead')"
                  v-model="formModel.translations[selectedLocale.lang].lead"
                  @change="handleChangeLead"
                ></v-textarea>
                <small
                  v-if="
                    messages['translations.' + selectedLocale.lang + '.lead']
                  "
                  style="color: red"
                >
                  {{
                    messages["translations." + selectedLocale.lang + ".lead"]
                  }}
                </small>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-if="formModel.translations[selectedLocale.lang]"
                v-model="formModel.translations[selectedLocale.lang].intro"
                :error-messages="
                  messages['translations.' + selectedLocale.lang + '.intro']
                "
                outlined="true"
                :rules="max_160"
                :label="$t('FORMS.intro_max_160') + ' ' + introLength"
                @input="calcIntroLength"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{
                  type: 'textarea',
                  label: 'FORM_INPUT_NAMES.og_description',
                }"
                customFieldName="og_description"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .og_description || ''
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-spacer></v-spacer> -->
            <v-col cols="3" sm="6" md="3">
              <v-select
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .news_top_right_banner_id
                "
                :items="getBanner(3, this, 'news_top_right_banner_id')"
                item-text="name"
                item-value="id"
                :label="$t('FORM_INPUT_NAMES.news_top_right_banner')"
                clearable="true"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <v-select
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .news_bottom_right_banner_id
                "
                :items="getBanner(3, this, 'news_bottom_right_banner_id')"
                item-text="name"
                item-value="id"
                :label="$t('FORM_INPUT_NAMES.news_bottom_right_banner')"
                clearable="true"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <v-select
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .news_under_lead_banner_id
                "
                :items="getBanner(2, this, 'news_under_lead_banner_id')"
                item-text="name"
                item-value="id"
                :label="$t('FORM_INPUT_NAMES.news_under_lead_banner')"
                clearable="true"
              ></v-select>
            </v-col>
            <v-col cols="3" sm="6" md="3">
              <v-select
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .news_bottom_banner_id
                "
                :items="getBanner(2, this, 'news_bottom_banner_id')"
                item-text="name"
                item-value="id"
                :label="$t('FORM_INPUT_NAMES.news_bottom_banner')"
                clearable="true"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12">
              <div style="max-width: 830px">
                {{ $t("FORMS.text") }}
                <ckeditor
                  id="cke"
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].text"
                  :error-messages="
                    messages['translations.' + selectedLocale.lang + '.text']
                  "
                  @input="
                    messages['translations.' + selectedLocale.lang + '.text'] =
                      ''
                  "
                  :config="editorConfig"
                  @namespaceloaded="onNamespaceLoaded"
                ></ckeditor>
                <small
                  v-if="
                    messages['translations.' + selectedLocale.lang + '.text']
                  "
                  style="color: red"
                >
                  {{
                    messages["translations." + selectedLocale.lang + ".text"]
                  }}
                </small>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                customFieldName="gallery"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .gallery || ''
                "
                :config="{
                  type: 'media_selector',
                  maxFiles: -1,
                  inputFields: [
                    {
                      name: 'alt_text',
                      label: 'FORM_INPUT_NAMES.alt_text',
                      type: 'text',
                    },
                    {
                      name: 'title',
                      label: 'FORM_INPUT_NAMES.title',
                      type: 'text',
                    },
                    {
                      name: 'description',
                      label: 'FORM_INPUT_NAMES.img_description',
                      type: 'text',
                    },
                  ],
                  selectButtonText: $t('FORM_INPUT_NAMES.gallery'),
                  resolution: '933x504',
                  initialColumnClass:
                    'col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12',
                }"
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <CustomFieldComponent
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                :config="{ type: 'text', label: 'FORMS.seo_title' }"
                customFieldName="seoTitle"
                :value="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoTitle
                "
                @handleChangeCustomFieldValue="handleChangeCustomFieldValue"
              >
              </CustomFieldComponent>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-if="
                  formModel.translations[selectedLocale.lang] &&
                  formModel.translations[selectedLocale.lang].custom_fields
                "
                outlined
                :label="$t('FORMS.seo_description')"
                v-model="
                  formModel.translations[selectedLocale.lang].custom_fields
                    .seoDescription
                "
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>
        </v-form>
      </v-card-text>

      <v-card-actions class="mb-2">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">
          {{ $t("FORMS.cancel") }}
        </v-btn>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalForm(false)"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
        <v-btn
          v-if="
            formModel.status != 2 &&
            ((permissionCan('create') && !formModel.id) ||
              permissionCan('update'))
          "
          color="primary"
          elevation="2"
          @click="handleSaveModalFormAndPreview"
        >
          Előnézet
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { PERMISSION_TO } from "./News";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import CustomFieldComponent from "@/view/components/CustomFieldComponent";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
import SiteService from "@/core/services/site.service.js";

//import Treeselect from "@riophae/vue-treeselect";
//import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const TRANSLATED_ATRIBUTES = [
  "name",
  "alternative_title",
  "slug",
  "text",
  "intro",
  //"author_name",
  "felcim",
  "lead",
  "video_content",
  "hovered_title",
  "rss_title",
];

export const INITIAL_TRANSLATED_CUSTOM_FIELDS = [
  "seoTitle",
  "seoDescription",
  "leading_article_image",
  "featured_image",
  "card_image",
  "gallery",

  "og_title",
  "og_description",
  "og_url",
  "og_site_name",
  "og_image",

  "news_top_right_banner_id",
  "news_bottom_right_banner_id",
  "news_under_lead_banner_id",
  "news_bottom_banner_id",
];

export const INITIAL_CUSTOM_FIELDS = {
  og_type: "",
};

export const initialFormData = () => ({
  id: null,
  status: 1,
  tags: [],
  related_news: [],
  translations: {},
  categories: [],
  custom_fields: INITIAL_CUSTOM_FIELDS,
  on_the_front_page: 0,
  on_the_main_page: 1,
  rss_nso: 0,
  news_author_id: null,
});

export default {
  name: "NewsForm",
  props: [
    "modalData",
    "permissions",
    "statuses",
    "endPoint",
    "newsCategoryCollection",
  ],
  components: { CustomFieldComponent },
  mixins: [formModelMixins, customFieldMixins],
  data() {
    return {
      result: null,
      languages: i18nService.userLanguages,
      selectedLocale: i18nService.userLanguages.find((item) => {
        return item.lang == i18nService.getUserActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      formValid: false,
      permissionTo: PERMISSION_TO,
      initialTransaltedCustomFields: INITIAL_TRANSLATED_CUSTOM_FIELDS,

      date_picker_published_at: false,
      published_at_date: null,
      time_picker_published_at: false,
      published_at_time: null,

      messages: {},
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 180) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      max_50: [(v) => !v || v.length <= 50 || "Maximum 50 karakter"],
      max_70: [(v) => !v || v.length <= 70 || "Maximum 70 karakter"],
      max_160: [(v) => !v || v.length <= 160 || "Maximum 160 karakter"],

      editorConfigIntro: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false
      },

      editorConfigLead: {
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins: "justify,font,copyformatting",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images",
        versionCheck: false
      },

      editorConfig: {
        contentsCss: "/ckeditor/style.css",
        htmlEncodeOutput: false,
        entities: false,
        disableNativeSpellChecker: false,
        extraPlugins:
          "justify,font,copyformatting,image2,specialchar,synergy_gallery,embed",
        extraAllowedContent: "iframe[*]",
        embed_provider: "//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&consent=0",
        // removeButtons:
        //   "Cut,Copy,Paste,Undo,Redo,Anchor,Underline,Strike,Subscript,Superscript",
        toolbar: [
          // [{ name: "basicstyles", items: ["Bold", "Italic"] }],
          ["Source"],
          ["Styles"],
          ["U", "Bold", "Italic"],
          ["Undo", "Redo"],
          ["About"],
          ["Image", "Link", "Table", "NumberedList", "BulletedList"],
          ["InsertSynergyGallery"],
          ["SpecialChar"],
          ["Embed"],
        ],
        stylesSet: [
          { name: "Normál", element: "p", styles: { color: "black" } },
          {
            name: "Idézet.",
            element: "div",
            attributes: { class: "ck--blockquote" },
          },
          {
            name: "Kiemelt.",
            element: "div",
            styles: { color: "#BE1622" },
            attributes: { class: "highlighted" },
          },
          {
            name: "Doboz",
            element: "div",
            attributes: { class: "alert alert-danger" },
          },
          {
            name: "Kiemelt",
            element: "div",
            styles: { color: "#EE7418" },
            attributes: {
              class: "text--xl c--orange fw-bold c--orange-highlighted",
            },
          },
          {
            name: "Idézet",
            element: "div",
            styles: { color: "#EE7418" },
            attributes: { class: "ck--blockquote text--xl c--orange" },
          },
          /*{
            name: "Narancs idézet",
            element: "q",
            styles: { color: "orange" },
          },*/
          {
            name: "Aláhúzott",
            element: "u",
            styles: { color: "black" },
          },

          {
            name: "Kép balra",
            element: "img",
            attributes: {
              style: "padding: 5px; margin-right: 5px",
              border: "2",
              align: "left",
            },
          },
        ],
        height: "45em",
        filebrowserBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Files",
        filebrowserImageBrowseUrl:
          window.location.origin +
          "/" +
          this.$router.resolve({
            name: "media_library",
            query: { component_in_window: 1 },
          }).href +
          "?type=Images&maxWidth=813",
        versionCheck: false
      },

      tagCollection: [],
      og_types: ["website", "article"],
      introLength: 160,
      RSSLength: 70,
    };
  },
  computed: {
    ...mapGetters([
      "newsCollection",
      "bannerCollection",
      "newsAuthorCollection",
    ]),
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },

    formTitle() {
      return this.formModel.id
        ? this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name
        : this.$t("MENU.NEW") + " " + this.$t("MENU.POST").toLowerCase();
    },

    translatedAttributes() {
      return TRANSLATED_ATRIBUTES;
    },
    initialFormData() {
      return initialFormData;
    },
    computedTagCollection() {
      let collection = [];
      this.tagCollection.forEach((item) => {
        if (item.locale == this.selectedLocale.lang) {
          collection.push(item.name);
        }
      });
      return collection;
    },
    computedRelatedNewsCollection() {
      let collection = [];
      this.newsCollection.forEach((item) => {
        if (item.id == this.formModel.id) {
          return;
        }
        if (item.translations[this.selectedLocale.lang]) {
          collection.push({
            id: item.id,
            label: item.translations[this.selectedLocale.lang].name,
          });
        }
      });
      return collection;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getUserActiveLanguage();
        });
        if (value.editedId) {
          this.loader = true;
          this.formModel = Object.assign({}, initialFormData());
          this.introLength = 160;
          this.RSSLength = 70;
          ApiService.get(this.endPoint + value.editedId)
            .then(({ data }) => {
              data.status = 1 * data.status;
              if (!data.custom_fields)
                data.custom_fields = INITIAL_CUSTOM_FIELDS;

              this.formModel = Object.assign({}, data);

              this.setTranslatedAttributes();

              if (data.published_at) {
                this.published_at_date = new Date(
                  Date.parse(data.published_at) -
                    new Date().getTimezoneOffset() * 60000
                )
                  .toISOString()
                  .substr(0, 10);
                this.published_at_time = this.getTimeFormat(
                  new Date(Date.parse(data.published_at))
                );
              } else {
                this.published_at_date = null;
                this.published_at_time = null;
              }

              this.calcIntroLength();
              this.calcRSSLength();
            })
            .catch((error) => {
              console.log("Error!: ", error);
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());
          this.setTranslatedAttributes();

          this.published_at_date = null;
          this.published_at_time = null;
          this.introLength = 160;
          this.RSSLength = 70;

          if (this.$refs.form) this.$refs.form.resetValidation();
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions(["fetchBanner", "fetchNewsAuthor"]),

    onNamespaceLoaded(CKEDITOR) {
      CKEDITOR.plugins.add("synergy_gallery", {
        icons: "imagebutton",
        init: function (editor) {
          editor.addCommand("insert_synergy_gallery", {
            exec: function (editor) {
              editor.insertHtml("<em>synergy_gallery</em>");
            },
          });
          editor.ui.addButton("InsertSynergyGallery", {
            icon: "imagebutton",
            label: "Geléria beszúrása",
            command: "insert_synergy_gallery",
            toolbar: "insert",
          });
        },
      });
    },
    handleSaveModalFormAndPreview() {
      this.handleSaveModalForm(true);
    },
    getPreviewUrl(model_id) {
      this.loader = true;
      let preview_endpoint =
        "news/admin/" + SiteService.getActiveSiteId() + "/news-preview/";
      ApiService.get(
        preview_endpoint + model_id + "/" + this.selectedLocale.lang
      )
        .then((response) => {
          window.open(response.data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    handleSaveModalForm(preview = false) {
      let model = this.deleteUnusedTranslations(
        Object.assign({}, this.formModel)
      );

      this.$refs.form.validate();
      if (!model.categories) model.categories = [];

      let published_at_time = this.published_at_time
        ? this.published_at_time + ":00"
        : "00:00:00";
      model.published_at = this.published_at_date
        ? this.published_at_date + " " + published_at_time
        : null;

      if (this.formValid) {
        this.resetErrorMessages();
        this.loader = true;
        if (model.id) {
          ApiService.put(this.endPoint + model.id, model)
            .then(() => {
              if (preview) {
                this.getPreviewUrl(model.id);
              } else {
                this.$emit("saveModalForm");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          if (
            !this.formModel.translations[this.selectedLocale.lang].video_content
          ) {
            model.translations[this.selectedLocale.lang].video_content = 0;
          }
          if (
            !this.formModel.translations[this.selectedLocale.lang].hovered_title
          ) {
            model.translations[this.selectedLocale.lang].hovered_title = 0;
          }

          ApiService.post(this.endPoint, model)
            .then((response) => {
              if (preview) {
                this.getPreviewUrl(response.data.id);
              } else {
                this.$emit("saveModalForm");
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                  console.log(this.messages);
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    fetchTag() {
      this.loader = true;
      ApiService.get("news/admin/" + SiteService.getActiveSiteId() + "/tag")
        .then(({ data }) => {
          this.tagCollection = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    deleteChip(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    deleteChipComboBox(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove) {
          array.splice(i, 1);
        }
      }
    },

    deleteChipAutocomplete(itemNeedToRemove, array) {
      for (let i = 0; i < array.length; i += 1) {
        if (array[parseInt(i, 10)] === itemNeedToRemove.id) {
          array.splice(i, 1);
        }
      }
    },
    getTimeFormat(date) {
      let hours = String(date.getHours()).padStart(2, "0");
      let minutes = String(date.getMinutes()).padStart(2, "0");
      return hours + ":" + minutes;
    },

    handleRssTitleValue() {
      let rss_title = "";
      if (this.formModel.categories[0]) {
        let firstCategory = this.$store.getters["getNewsCategoryByID"](
          this.formModel.categories[0]
        );
        if (firstCategory) {
          rss_title += firstCategory.name + ": ";
        }
      }
      rss_title += this.formModel.translations[this.selectedLocale.lang].name;
      this.$set(
        this.formModel.translations[this.selectedLocale.lang],
        "rss_title",
        rss_title
      );
      let formModel = this.formModel;
      this.formModel = Object.assign({}, this.initialFormData());
      this.formModel = Object.assign({}, formModel);
      this.calcRSSLength();
    },

    handleChangeName() {
      if (
        !this.formModel.translations[this.selectedLocale.lang]
          .alternative_title ||
        this.formModel.translations[this.selectedLocale.lang].alternative_title
          .length == 0
      ) {
        this.$set(
          this.formModel.translations[this.selectedLocale.lang],
          "alternative_title",
          this.formModel.translations[this.selectedLocale.lang].name
        );

        let formModel = this.formModel;
        this.formModel = Object.assign({}, this.initialFormData());
        this.formModel = Object.assign({}, formModel);
      }

      this.handleRssTitleValue();

      let seoTitle = "";
      if (this.formModel.categories[0]) {
        let firstCategory = this.$store.getters["getNewsCategoryByID"](
          this.formModel.categories[0]
        );
        let ogTitle = "";

        if (firstCategory) {
          ogTitle += firstCategory.name + ": ";
          seoTitle += firstCategory.name + ": ";
        }

        ogTitle += this.formModel.translations[this.selectedLocale.lang].name;
        this.formModel.translations[
          this.selectedLocale.lang
        ].custom_fields.og_title = ogTitle;
      }

      seoTitle += this.formModel.translations[this.selectedLocale.lang].name;
      this.handleChangeCustomFieldValue("seoTitle", seoTitle);
    },

    handleChangeCategory() {
      let firstCategory = this.$store.getters["getNewsCategoryByID"](
        this.formModel.categories[0]
      );
      let ogTitle = "";
      let seoTitle = "";
      if (firstCategory) {
        ogTitle += firstCategory.name + ": ";
        seoTitle += firstCategory.name + ": ";
      }

      ogTitle += this.formModel.translations[this.selectedLocale.lang].name;
      this.formModel.translations[
        this.selectedLocale.lang
      ].custom_fields.og_title = ogTitle;

      seoTitle += this.formModel.translations[this.selectedLocale.lang].name;
      this.handleChangeCustomFieldValue("seoTitle", seoTitle);

      this.handleRssTitleValue();
    },

    handleChangeLead() {
      if (
        !this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription ||
        this.formModel.translations[this.selectedLocale.lang].custom_fields
          .seoDescription.length == 0
      ) {
        this.handleChangeCustomFieldValue(
          "seoDescription",
          this.formModel.translations[this.selectedLocale.lang].lead.slice(
            0,
            160
          )
        );
      }

      if (
        !this.formModel.translations[this.selectedLocale.lang].custom_fields
          .og_description ||
        this.formModel.translations[this.selectedLocale.lang].custom_fields
          .og_description.length == 0
      ) {
        this.handleChangeCustomFieldValue(
          "og_description",
          this.formModel.translations[this.selectedLocale.lang].lead
        );
      }
      if (
        !this.formModel.translations[this.selectedLocale.lang].intro ||
        this.formModel.translations[this.selectedLocale.lang].intro == 0
      ) {
        this.formModel.translations[this.selectedLocale.lang].intro =
          this.formModel.translations[this.selectedLocale.lang].lead;
      }
      this.calcIntroLength();
    },

    getBanner(type, context, custom_field_name) {
      let originalBannerId =
        context.formModel.translations[context.selectedLocale.lang]
          .custom_fields[custom_field_name];
      console.log(originalBannerId);
      return this.bannerCollection.filter((banner) => {
        if (banner.type == type) {
          if (originalBannerId == banner.id) {
            return banner;
          } else if (banner.in_admin_lists == 1) {
            return banner;
          }
        }
      });
      //return this.bannerCollection.filter((banner) => banner.type == type && banner.in_admin_lists == 1);
    },

    calcIntroLength() {
      let len =
        this.formModel.translations[this.selectedLocale.lang].intro.length;
      this.introLength = 160 - len;
    },

    calcRSSLength() {
      let len =
        this.formModel.translations[this.selectedLocale.lang].rss_title.length;
      this.RSSLength = 70 - len;
    },
  },

  mounted() {
    this.fetchTag();
    this.setTranslatedAttributes();
    this.fetchBanner();
    this.fetchNewsAuthor();
  },
};
</script>

<!-- <style>
.highlighted {
  background-color: green;
}
</style> -->
